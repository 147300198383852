<template>
  <div class="page-header-index-wide">
    <div class="row-box">
        <div class="box">
          <img src="../../assets/home/yq.png" />
          <p class="icon bk1">
          </p>
          <p class="content">仪器数量:<span class="num">{{homeStatistics.assetsCount?homeStatistics.assetsCount:0}}</span>个</p>
        </div>
        <div class="box">
          <img src="../../assets/home/yyrs.png" />
          <p class="icon bk2">
          </p>
          <p class="content">预约人数:<span class="num">{{homeStatistics.appointmentCount?homeStatistics.appointmentCount:0}}</span>人</p>
        </div>
        <div class="box">
            <img src="../../assets/home/ljcs.png" />
          <p class="icon">
          </p>
          <p class="content">预约次数:<span class="num">{{homeStatistics.appointmentTimes?homeStatistics.appointmentTimes:0}}</span>次</p>
        </div>
        <div class="box">
            <img src="../../assets/home/kssc.png" />
          <p class="icon bk4">
          </p>
          <p class="content">预约时长:<span class="num">{{homeStatistics.appointmentHours?homeStatistics.appointmentHours:0}}</span>小时</p>
        </div>
        <div class="box">
            <img src="../../assets/home/sz.png" />
          <p class="icon bk5">
          </p>
          <p class="content">使用时长:<span class="num">{{homeStatistics.useHours?homeStatistics.useHours:0}}</span>小时</p>
        </div>
    </div>
    <a-row>
      <a-col :span="24">
        <a-card :loading="loading" :bordered="false" title="" :style="{ marginTop: '24px' }">
          <a-row>
            <a-col :span="24">
              <!-- <div style="text-align: center; font-size: 20px;text-shadow: 2px 2px 5px red;">欢迎进入毅力实验通设备管理系统</div>
              <div style="display: flex;justify-content: center;height: 400px;align-items: center;">
                <img style="width:500px;height:287px;" src="../../assets/wel.jpg" />
              </div> -->
              <div>
                 <div class="monitorTitle"><div class="before"></div><div>实时借还动态</div></div>
                 <div class="monitorHeader">
                   <div>设备名称</div>
                   <div>柜门</div>
                   <div>状态</div>
                   <div>位置</div>
                   <div>开柜时间</div>
                 </div>
                 <div class="monitorBox" v-if="monitorList.length">
                    <div v-for="item in monitorList">
                      <div class="monitorMenuContent">

                        <div>
{{item.assetsName}}
                        </div>
                        <div>
{{item.cabinetName}}{{item.cabinetNumber}}门
                        </div>
                        <div>
 {{item.type==4?"借用":"归还"}}
                        </div>
                        <div>
{{item.position}}
                        </div>
                        <div>
{{item.createTime}}
                        </div>
                      </div>
                    </div>
                 </div>
                 <div v-else class="empty">
                    暂无数据
                 </div>
              </div>
            </a-col>
          </a-row>
        </a-card>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="24">
        <a-card :loading="loading" :bordered="false" title="" :style="{ marginTop: '24px' }">
          <a-row>
            <a-col :span="24">
              <!-- <div style="text-align: center; font-size: 20px;text-shadow: 2px 2px 5px red;">欢迎进入毅力实验通设备管理系统</div>
              <div style="display: flex;justify-content: center;height: 400px;align-items: center;">
                <img style="width:500px;height:287px;" src="../../assets/wel.jpg" />
              </div> -->
              <div>
                 <div class="monitorTitle"><div class="before"></div><div>实时反馈</div></div>
                 <div class="monitorHeader">
                   <div>反馈人</div>
                   <div>手机号</div>
                   <div>班级</div>
                   <div>反馈时间</div>
                   <div>反馈内容</div>
                 </div>
                 <div class="monitorBox" v-if="feedBackList.length">
                    <div v-for="item in feedBackList">
                      <div class="monitorMenuContent">
                        <div>
                          {{item.studentName}}
                        </div>
                        <div>
                          {{item.phone}}
                        </div>
                        <div>
                          {{item.className}}
                        </div>
                        <div>
                          {{item.createTime}}
                        </div>
                        <div>
                          {{item.description}}
                        </div>
                      </div>
                    </div>
                 </div>
                 <div v-else class="empty">
                    暂无数据
                 </div>
              </div>
            </a-col>
          </a-row>
          
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
  import ChartCard from '@/components/ChartCard'
  import ACol from "ant-design-vue/es/grid/Col"
  import ATooltip from "ant-design-vue/es/tooltip/Tooltip"
  import MiniArea from '@/components/chart/MiniArea'
  import MiniBar from '@/components/chart/MiniBar'
  import MiniProgress from '@/components/chart/MiniProgress'
  import RankList from '@/components/chart/RankList'
  import Bar from '@/components/chart/Bar'
  import LineChartMultid from '@/components/chart/LineChartMultid'
  import HeadInfo from '@/components/tools/HeadInfo.vue'

  import Trend from '@/components/Trend'
  import { getLoginfo,getVisitInfo,getNewmonitor,getHomePage,getFeedBack} from '@/api/api'

  const rankList = []
  for (let i = 0; i < 7; i++) {
    rankList.push({
      name: '白鹭岛 ' + (i+1) + ' 号店',
      total: 1234.56 - i * 100
    })
  }
  const barData = []
  for (let i = 0; i < 12; i += 1) {
    barData.push({
      x: `${i + 1}月`,
      y: Math.floor(Math.random() * 1000) + 200
    })
  }
  export default {
    name: "IndexChart",
    components: {
      ATooltip,
      ACol,
      ChartCard,
      MiniArea,
      MiniBar,
      MiniProgress,
      RankList,
      Bar,
      Trend,
      LineChartMultid,
      HeadInfo
    },
    data() {
      return {
        homeStatistics:{},
        monitorList:[],
        feedBackList:[],
        loading: true,
        center: null,
        rankList,
        barData,
        loginfo:{},
        visitFields:['ip','visit'],
        visitInfo:[],
        indicator: <a-icon type="loading" style="font-size: 24px" spin />
      }
    },
    created() {
      setTimeout(() => {
        this.loading = !this.loading
      }, 1000)
      this.initLogInfo();
      this.initNewCabint();
      this.initHomePage();
    },
    methods: {
      initHomePage(){
        console.log(66666)
        getHomePage().then(res=>{
          if(res.success){
            this.homeStatistics=res.result
           }
         })
      },
      initLogInfo () {
        getLoginfo(null).then((res)=>{
          if(res.success){
            Object.keys(res.result).forEach(key=>{
              res.result[key] =res.result[key]+""
            })
            this.loginfo = res.result;
          }
        })
        getVisitInfo().then(res=>{
          if(res.success){
             this.visitInfo = res.result;
           }
         })
      },initNewCabint(){
        getNewmonitor({pageNo:1,pageSize:20}).then((res)=>{
          if(res.success){
            this.monitorList=res.result;
            setTimeout(()=>{
              this.initNewCabint();
            },60000)
          }
        })
        getFeedBack({pageNo:1,pageSize:20}).then((res)=>{
          if(res.success){
            this.feedBackList=res.result;
          }
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  .circle-cust{
    position: relative;
    top: 28px;
    left: -100%;
  }
  .extra-wrapper {
    line-height: 55px;
    padding-right: 24px;

    .extra-item {
      display: inline-block;
      margin-right: 24px;

      a {
        margin-left: 24px;
      }
    }
  }

  /* 首页访问量统计 */
  .head-info {
    position: relative;
    text-align: left;
    padding: 0 32px 0 0;
    min-width: 125px;

    &.center {
      text-align: center;
      padding: 0 32px;
    }

    span {
      color: rgba(0, 0, 0, .45);
      display: inline-block;
      font-size: .95rem;
      line-height: 42px;
      margin-bottom: 4px;
    }
    p {
      line-height: 42px;
      margin: 0;
      a {
        font-weight: 600;
        font-size: 1rem;
      }
    }
  }
  .monitorTitle{
    display: flex;
    align-items: center;
  }
  .before {
    margin-right: 3px;
    width: 5px;
    height: 15px;
    background: #14D0b4;
    border-radius: 5px;
  }
  .empty{
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .monitorHeader{
    display: flex;
    border: 1px solid #14D0b4;
    margin-top: 20px;
  }
  .monitorHeader div{
    text-align: center;
    padding: 8px;
    width:20%;
    border-right: 1px solid #14D0b4;
  }
  .monitorMenuContent{
    border-top:none !important;
  }
  .monitorHeader div:last-child,.monitorMenuContent div:last-child{
   
    border-right: none !important;
  }
  .monitorMenuContent{
display: flex;
    border: 1px solid #14D0b4;
  }
  .monitorMenuContent div{
    text-align: center;
    padding: 8px;
    width:20%;
    border-right: 1px solid #14D0b4;
  }
  .box{
    position: relative;
    padding:10px;
    height:70px;
    min-width:173px;
    background: #fff;
    margin:10px 0;
    border-radius: 5px;
    display: flex;
    align-content: center;
    img{
        width: 20px;
        height: 20px;
        position: absolute;
        top: 25px;
        left: 25px;
      }
    .icon{
      position: relative;
      margin-right:5px;
      margin-right: 5px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 1px solid #ccc;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ccc;
      opacity: .1;
    }
    .content{
      height:50px;
      display:flex;
      justify-content: center;
      align-items: center;
    }
  }
.row-box{
  display: flex;
  width:100%;
  justify-content: space-around;
}
.bk1{
 background: blue !important;
}
.bk2{
  background:green !important;
}
.bk4{
  background:red !important;
}
.bk5{
  background:#FFFF00 !important;
}
.num{
  color:green !important;
}
</style>