var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-header-index-wide" },
    [
      _c("div", { staticClass: "row-box" }, [
        _c("div", { staticClass: "box" }, [
          _c("img", { attrs: { src: require("../../assets/home/yq.png") } }),
          _c("p", { staticClass: "icon bk1" }),
          _c("p", { staticClass: "content" }, [
            _vm._v("仪器数量:"),
            _c("span", { staticClass: "num" }, [
              _vm._v(
                _vm._s(
                  _vm.homeStatistics.assetsCount
                    ? _vm.homeStatistics.assetsCount
                    : 0
                )
              )
            ]),
            _vm._v("个")
          ])
        ]),
        _c("div", { staticClass: "box" }, [
          _c("img", { attrs: { src: require("../../assets/home/yyrs.png") } }),
          _c("p", { staticClass: "icon bk2" }),
          _c("p", { staticClass: "content" }, [
            _vm._v("预约人数:"),
            _c("span", { staticClass: "num" }, [
              _vm._v(
                _vm._s(
                  _vm.homeStatistics.appointmentCount
                    ? _vm.homeStatistics.appointmentCount
                    : 0
                )
              )
            ]),
            _vm._v("人")
          ])
        ]),
        _c("div", { staticClass: "box" }, [
          _c("img", { attrs: { src: require("../../assets/home/ljcs.png") } }),
          _c("p", { staticClass: "icon" }),
          _c("p", { staticClass: "content" }, [
            _vm._v("预约次数:"),
            _c("span", { staticClass: "num" }, [
              _vm._v(
                _vm._s(
                  _vm.homeStatistics.appointmentTimes
                    ? _vm.homeStatistics.appointmentTimes
                    : 0
                )
              )
            ]),
            _vm._v("次")
          ])
        ]),
        _c("div", { staticClass: "box" }, [
          _c("img", { attrs: { src: require("../../assets/home/kssc.png") } }),
          _c("p", { staticClass: "icon bk4" }),
          _c("p", { staticClass: "content" }, [
            _vm._v("预约时长:"),
            _c("span", { staticClass: "num" }, [
              _vm._v(
                _vm._s(
                  _vm.homeStatistics.appointmentHours
                    ? _vm.homeStatistics.appointmentHours
                    : 0
                )
              )
            ]),
            _vm._v("小时")
          ])
        ]),
        _c("div", { staticClass: "box" }, [
          _c("img", { attrs: { src: require("../../assets/home/sz.png") } }),
          _c("p", { staticClass: "icon bk5" }),
          _c("p", { staticClass: "content" }, [
            _vm._v("使用时长:"),
            _c("span", { staticClass: "num" }, [
              _vm._v(
                _vm._s(
                  _vm.homeStatistics.useHours ? _vm.homeStatistics.useHours : 0
                )
              )
            ]),
            _vm._v("小时")
          ])
        ])
      ]),
      _c(
        "a-row",
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-card",
                {
                  style: { marginTop: "24px" },
                  attrs: { loading: _vm.loading, bordered: false, title: "" }
                },
                [
                  _c(
                    "a-row",
                    [
                      _c("a-col", { attrs: { span: 24 } }, [
                        _c("div", [
                          _c("div", { staticClass: "monitorTitle" }, [
                            _c("div", { staticClass: "before" }),
                            _c("div", [_vm._v("实时借还动态")])
                          ]),
                          _c("div", { staticClass: "monitorHeader" }, [
                            _c("div", [_vm._v("设备名称")]),
                            _c("div", [_vm._v("柜门")]),
                            _c("div", [_vm._v("状态")]),
                            _c("div", [_vm._v("位置")]),
                            _c("div", [_vm._v("开柜时间")])
                          ]),
                          _vm.monitorList.length
                            ? _c(
                                "div",
                                { staticClass: "monitorBox" },
                                _vm._l(_vm.monitorList, function(item) {
                                  return _c("div", [
                                    _c(
                                      "div",
                                      { staticClass: "monitorMenuContent" },
                                      [
                                        _c("div", [
                                          _vm._v(
                                            "\n" +
                                              _vm._s(item.assetsName) +
                                              "\n                        "
                                          )
                                        ]),
                                        _c("div", [
                                          _vm._v(
                                            "\n" +
                                              _vm._s(item.cabinetName) +
                                              _vm._s(item.cabinetNumber) +
                                              "门\n                        "
                                          )
                                        ]),
                                        _c("div", [
                                          _vm._v(
                                            "\n " +
                                              _vm._s(
                                                item.type == 4 ? "借用" : "归还"
                                              ) +
                                              "\n                        "
                                          )
                                        ]),
                                        _c("div", [
                                          _vm._v(
                                            "\n" +
                                              _vm._s(item.position) +
                                              "\n                        "
                                          )
                                        ]),
                                        _c("div", [
                                          _vm._v(
                                            "\n" +
                                              _vm._s(item.createTime) +
                                              "\n                        "
                                          )
                                        ])
                                      ]
                                    )
                                  ])
                                }),
                                0
                              )
                            : _c("div", { staticClass: "empty" }, [
                                _vm._v(
                                  "\n                    暂无数据\n                 "
                                )
                              ])
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-card",
                {
                  style: { marginTop: "24px" },
                  attrs: { loading: _vm.loading, bordered: false, title: "" }
                },
                [
                  _c(
                    "a-row",
                    [
                      _c("a-col", { attrs: { span: 24 } }, [
                        _c("div", [
                          _c("div", { staticClass: "monitorTitle" }, [
                            _c("div", { staticClass: "before" }),
                            _c("div", [_vm._v("实时反馈")])
                          ]),
                          _c("div", { staticClass: "monitorHeader" }, [
                            _c("div", [_vm._v("反馈人")]),
                            _c("div", [_vm._v("手机号")]),
                            _c("div", [_vm._v("班级")]),
                            _c("div", [_vm._v("反馈时间")]),
                            _c("div", [_vm._v("反馈内容")])
                          ]),
                          _vm.feedBackList.length
                            ? _c(
                                "div",
                                { staticClass: "monitorBox" },
                                _vm._l(_vm.feedBackList, function(item) {
                                  return _c("div", [
                                    _c(
                                      "div",
                                      { staticClass: "monitorMenuContent" },
                                      [
                                        _c("div", [
                                          _vm._v(
                                            "\n                          " +
                                              _vm._s(item.studentName) +
                                              "\n                        "
                                          )
                                        ]),
                                        _c("div", [
                                          _vm._v(
                                            "\n                          " +
                                              _vm._s(item.phone) +
                                              "\n                        "
                                          )
                                        ]),
                                        _c("div", [
                                          _vm._v(
                                            "\n                          " +
                                              _vm._s(item.className) +
                                              "\n                        "
                                          )
                                        ]),
                                        _c("div", [
                                          _vm._v(
                                            "\n                          " +
                                              _vm._s(item.createTime) +
                                              "\n                        "
                                          )
                                        ]),
                                        _c("div", [
                                          _vm._v(
                                            "\n                          " +
                                              _vm._s(item.description) +
                                              "\n                        "
                                          )
                                        ])
                                      ]
                                    )
                                  ])
                                }),
                                0
                              )
                            : _c("div", { staticClass: "empty" }, [
                                _vm._v(
                                  "\n                    暂无数据\n                 "
                                )
                              ])
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }