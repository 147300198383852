var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "rank" }, [
    _c("h4", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
    _c(
      "ul",
      {
        staticClass: "list",
        style: {
          height: _vm.height ? _vm.height + "px" : "auto",
          overflow: "auto"
        }
      },
      _vm._l(_vm.list, function(item, index) {
        return _c("li", { key: index }, [
          _c("span", { class: index < 3 ? "active" : null }, [
            _vm._v(_vm._s(index + 1))
          ]),
          _c("span", [_vm._v(_vm._s(item.name))]),
          _c("span", [_vm._v(_vm._s(item.total))])
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }