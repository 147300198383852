var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { bordered: false } },
    [
      _c(
        "a-tabs",
        { attrs: { defaultActiveKey: "1" } },
        [
          _c(
            "a-tab-pane",
            { key: "1", attrs: { tab: "柱状图" } },
            [
              _c("bar", {
                attrs: {
                  title: "销售额排行",
                  dataSource: _vm.barData,
                  height: _vm.height
                }
              })
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "2", attrs: { tab: "多列柱状图" } },
            [
              _c("bar-multid", {
                attrs: { title: "多列柱状图", height: _vm.height }
              })
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "3", attrs: { tab: "迷你柱状图" } },
            [
              _c("mini-bar", {
                attrs: { dataSource: _vm.barData, width: 400, height: 200 }
              })
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "4", attrs: { tab: "面积图" } },
            [
              _c("area-chart-ty", {
                attrs: {
                  title: "销售额排行",
                  dataSource: _vm.areaData,
                  x: "月份",
                  y: "销售额",
                  height: _vm.height
                }
              })
            ],
            1
          ),
          _c("a-tab-pane", { key: "5", attrs: { tab: "迷你面积图" } }, [
            _c(
              "div",
              {
                staticStyle: {
                  "padding-top": "100px",
                  width: "600px",
                  height: "200px"
                }
              },
              [
                _c("mini-area", {
                  attrs: {
                    dataSource: _vm.areaData,
                    x: "月份",
                    y: "销售额",
                    height: _vm.height
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "a-tab-pane",
            { key: "6", attrs: { tab: "多行折线图" } },
            [
              _c("line-chart-multid", {
                attrs: { title: "多行折线图", height: _vm.height }
              })
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "7", attrs: { tab: "饼图" } },
            [_c("pie", { attrs: { title: "饼图", height: _vm.height } })],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "8", attrs: { tab: "雷达图" } },
            [_c("radar", { attrs: { title: "雷达图", height: _vm.height } })],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "9", attrs: { tab: "仪表盘" } },
            [
              _c("dash-chart-demo", {
                attrs: { title: "仪表盘", value: 9, height: _vm.height }
              })
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "10", attrs: { tab: "进度条" } },
            [
              _c("mini-progress", {
                attrs: { percentage: 30, target: 40, height: 30 }
              }),
              _c("mini-progress", {
                attrs: {
                  percentage: 51,
                  target: 60,
                  height: 30,
                  color: "#FFA500"
                }
              }),
              _c("mini-progress", {
                attrs: {
                  percentage: 66,
                  target: 80,
                  height: 30,
                  color: "#1E90FF"
                }
              }),
              _c("mini-progress", {
                attrs: {
                  percentage: 74,
                  target: 70,
                  height: 30,
                  color: "#FF4500"
                }
              }),
              _c("mini-progress", {
                attrs: {
                  percentage: 92,
                  target: 100,
                  height: 30,
                  color: "#49CC49"
                }
              })
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "11", attrs: { tab: "排名列表" } },
            [
              _c("rank-list", {
                staticStyle: { width: "600px", margin: "0 auto" },
                attrs: { title: "门店销售排行榜", list: _vm.rankList }
              })
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "12", attrs: { tab: "TransferBar" } },
            [
              _c("transfer-bar", {
                attrs: {
                  title: "年度消耗流量一览表",
                  data: _vm.barData,
                  x: "月份",
                  y: "流量(Mb)",
                  height: _vm.height
                }
              })
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "13", attrs: { tab: "Trend" } },
            [
              _c("trend", {
                attrs: { title: "Trend", term: "Trend：", percentage: 30 }
              })
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "14", attrs: { tab: "Liquid" } },
            [_c("liquid", { attrs: { height: _vm.height } })],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "15", attrs: { tab: "BarAndLine" } },
            [_c("bar-and-line", { attrs: { height: _vm.height } })],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }